<template>
  <a-modal
      :title="isEditing ?'Level Düzenle': 'Level Ekle'"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      width="800px"
  >


    <a-row type="flex">

      <a-col :flex="2">
        Level İsimi
      </a-col>

      <a-col>
        <a-input v-model:value="levelTitle" style="width: 600px"></a-input>
      </a-col>

    </a-row>


    <a-row type="flex">

      <a-col :flex="2">
        Kurs Seçin
      </a-col>

      <a-col>
        <a-select
            ref="course_select"

            v-model:value="selectedCourse"
            style="width: 600px"
            :options="courseList"
        >
        </a-select>
      </a-col>

    </a-row>


    <template #footer>
      <a-button key="iptal" @click="visible = false">İptal</a-button>
      <a-button key="submit" type="primary" :loading="confirmLoading" @click="handleOk">
        {{ isEditing ? 'Düzenle' : 'Oluştur' }}
      </a-button>
    </template>

  </a-modal>
</template>

<script>
import { ref} from 'vue'
import axios from 'axios'

export default {
  name: 'AddOrEditLevel',
  emits: ['loadLevels'],
  setup(props, {emit}) {


    const isEditing = ref(false)

    const visible = ref(false)
    const confirmLoading = ref(false)

    const id = ref(null)
    const levelTitle = ref('')

    const courseList = ref([])
    const selectedCourse = ref(null)


    const showModal = async (_selectedLevel) => {

      await axios.get('/courses')
          .then((response)=>{

            courseList.value = response.data.courses.map(item => ({label:`${item.category.name}: ${item.name}` ,value:item.id}))
            selectedCourse.value = courseList.value[0].value

          })


      isEditing.value =false
      id.value = null
      levelTitle.value = ''

      if (_selectedLevel) {
        isEditing.value = true
        id.value = _selectedLevel.id
        selectedCourse.value = _selectedLevel.course.id
        levelTitle.value = _selectedLevel.title
      }

      visible.value = true

    }

    const createLevel = async () => {
      return axios.post('/levels', {
        title: levelTitle.value,
        course_id:selectedCourse.value,
      })
    }

    const updateLevel = async () => {
      return axios.post('/levels/update', {
        level_id: id.value,
        course_id:selectedCourse.value,
        title: levelTitle.value,
      })
    }


    const handleOk = () => {

      confirmLoading.value = true

      let funcToBeCalled = createLevel

      if (isEditing.value) {
        funcToBeCalled = updateLevel

      }

      funcToBeCalled()
          .then(() => {

            emit('loadLevels')

          })
          .finally(() => {
            visible.value = false
            confirmLoading.value = false
          })


    }


    return {
      selectedCourse ,
      courseList ,
      levelTitle,
      isEditing,
      visible,
      confirmLoading,
      showModal,
      handleOk
    }

  }


}
</script>
