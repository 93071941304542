<template>

  <a-row>
    <a-card class="general_card" :bordered="false" title="Level Listesi" :loading="loading">
      <template #extra>
        <UnorderedListOutlined/>

        <a-button class="ant-btn-primary" @click="showMyModal()">
          <PlusOutlined/>
          Ekle
        </a-button>
        <!--        <router-link class="ant-btn-primary" tag="a" to="/new_company">-->
        <!--          <PlusOutlined/>-->
        <!--          Ekle-->
        <!--        </router-link>-->

      </template>


      <a-col :span="24">
        <AddOrEditLevels v-if="!loading" ref="addOrEditLevels" @loadLevels="getLevels()"/>

        <a-table :data-source="levels" :columns="columns" @change="handleChange" :row-key="record => record.id">

          <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
              <a-input
                  ref="searchInput"
                  :placeholder="`Search ${column.dataIndex}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block"
                  @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                  @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
              />
              <a-button
                  type="primary"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                <template #icon>
                  <SearchOutlined/>
                </template>
                Search
              </a-button>
              <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                Reset
              </a-button>
            </div>
          </template>

          <template #customRender="{ text }">
            {{ text }}
          </template>

          <template #actions="{ record,index }">

            <a-space>
              <a-button type="primary" @click="showMyModal(record)">
                <EditOutlined/>
                Düzenle
              </a-button>
              <router-link
                  :to="`/levels/${record.id}/files`"
                  target="_blank"
              >
                <a-button>
                  <FileOutlined/>
                  Dosyalar
                </a-button>
              </router-link>
              <a-button @click="hideOrShow(record.id,index)" :loading="hideOrShowButtons[index]">
                {{ record.hidden ? 'Göster' : 'Gizle' }}
              </a-button>

              <a-popconfirm title="Leveli silmek istediğinizden emin misin ?" @confirm="deleteLevel(record)">
                <a-button danger :loading="record.is_deleting">
                  <DeleteOutlined/>
                  Sil
                </a-button>
              </a-popconfirm>


            </a-space>


          </template>
        </a-table>
      </a-col>

    </a-card>
  </a-row>
</template>

<script>
import {
  EditOutlined,
  UnorderedListOutlined,
  PlusOutlined,
  SearchOutlined,
  FileOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
import {onMounted, reactive, computed, getCurrentInstance, toRefs, ref, inject} from 'vue'
import axios from 'axios'
import AddOrEditLevels from './components/AddOrEditLevels.vue'
import {selectedLevel} from '../../composables/useLevelFiles'

export default {
  name: 'LevelList',
  computed: {
    selectedLevel() {
      return selectedLevel
    }
  },
  components: {
    FileOutlined,
    SearchOutlined,
    AddOrEditLevels,
    EditOutlined,
    PlusOutlined,
    DeleteOutlined,
    UnorderedListOutlined
  },

  setup() {

    const localState = reactive({levels: [], filter: {}})
    const axios = inject('axios')
    const loading = ref(true)

    const addOrEditLevels = ref(null)

    const state = reactive({
      searchText: '',
      searchedColumn: ''
    })

    const searchInput = ref()

    const getLevels = () => {

      axios.get('/levels')
          .then(response => {

            localState.levels = response.data.levels.map(level => {
              level.is_deleting = ref(false)
              return level
            })

            loading.value = false

          })
    }

    onMounted(() => {

      getLevels()

    })
    const columns = computed(() => {

      return [
        {
          key: 'id',
          title: 'ID',
          dataIndex: 'id',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) =>
              record.id.toString().toLowerCase().includes(value.toLowerCase()),

          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {

                searchInput.value.focus()
              }, 100)
            }
          }
        },

        {
          key: 'name',
          title: 'İsim',
          dataIndex: 'title',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) =>
              record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {

                searchInput.value.focus()
              }, 100)
            }
          }
        },
        {
          key: 'course.id',
          title: 'Kurs',
          customRender: ({text, record, index}) => {
            return `${record.course.category.name}: ${record.course.name}`
          }
        },
        {
          key: 'actions',
          dataIndex: 'actions',
          slots: {
            customRender: 'actions'
          }
        }

      ]
    })
    const handleChange = (pagination, filters, sorter) => {
      localState.filter = filters
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm()
      state.searchText = selectedKeys[0]
      state.searchedColumn = dataIndex
    }

    const handleReset = clearFilters => {
      clearFilters()
      state.searchText = ''
    }

    const showMyModal = (selectedLevels) => {
      addOrEditLevels.value.showModal(selectedLevels)
    }

    const hideOrShowButtons = ref(new Array(localState.levels.length).fill(false))
    const hideOrShow = (id, index) => {
      hideOrShowButtons.value[index] = true
      axios.post(`/levels/${id}/hide-unhide`)
          .then(() => {
            getLevels()
          })
          .finally(() => hideOrShowButtons.value[index] = false)


    }

    const deleteLevel = (record) => {

      record.is_deleting = true
      axios.delete(`/levels/${record.id}`)
          .then(() => {
            getLevels()
          })
          .finally(() => record.is_deleting = false)


    }


    return {
      deleteLevel,
      addOrEditLevels,
      showMyModal,
      getLevels,
      ...toRefs(localState),
      hideOrShowButtons,
      columns,
      handleChange,
      loading,
      handleSearch,
      handleReset,
      searchText: '',
      searchInput,
      searchedColumn: '',
      hideOrShow
    }
  }

}
</script>
<style lang="scss" scoped>

.ant-popover-inner-content p {
  max-width: 295px;
  overflow-x: auto;
  max-height: 50px;
  white-space: nowrap;
  border-bottom: 1px solid #d8d8d8;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: #d8d8d8;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #1890FF;
    border-radius: 2px;
  }
}
</style>
